import $ from 'jquery'
import '../../style/app/bootstrap-modal.less'
import {Bloodhound} from "corejs-typeahead/index"
import 'corejs-typeahead'
import '../../style/app/typeahead.css'

$.expr[":"].caseInsensitiveContains = function (a, i, m) {
    return $(a).text().toUpperCase()
        .indexOf(m[3].toUpperCase()) >= 0;
};

function search(searchVal) {
    var $articles = $("article:caseInsensitiveContains(" + searchVal + ")");
    $("article").hide();
    $articles.show();
    if ($articles.length === 0) { $("#searchInfo").removeClass("hidden");}
    else {$("#searchInfo").addClass("hidden");}
}

$(document).ready(function () {
    //$(".js-hide").on("click",
    //    function () {

    //        var region = $(this).attr("data-region");
    //        $("article").hide();
    //        $("article[data-region*=" + region + "]").show();
    //    });

    $(".js-hide").click(function () {
        var searchValue = $(this).attr("data-region");
        $("#txt-search").val(searchValue);
        search(searchValue);
    });

    $(".js-showall").click(function () {
        $("#txt-search").val("");
        $("article").show();
    });

    $("form input").keydown(function (event) {
        if (event.keyCode === 13) {
            $(this).blur();
            event.preventDefault();
        }
    });

    $("#txt-search").change(function () {
        search($(this).val());
    });

    $("#btn-search").on('click',
        function () {
            search($('#txt-search').val());
            $(this).blur();
        }
    );

    $('a[href="#search"]').click(function() {
        var searchValue = $(this).attr("data-searchValue");
        $("#txt-search").val(searchValue);
        search(searchValue);
    });
});

$(document).ready(function() {

    var searchResults = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/api/searchvalues?query=%QUERY',
            wildcard: '%QUERY'
        }
    });

    $("#txt-search").typeahead({
        minLength: 2,
        highlight: true
    }, {
        name: 'searchResults',
        display: 'value',
        source: searchResults,
        templates: {
            suggestion: function (data) {
                return '<div>' + data.value + '  <span class="tt-resultHint">(' + data.description + ')</span></div>';
            }
        }
    });
});